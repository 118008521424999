/* ========================================================
*
* MVP Ready - Lightweight & Responsive Admin Template
*
* =========================================================
*
* File: mvpready-admin.css, mvpready-admin-*.css
* Theme Version: 2.1.0
* Bootstrap Version: 3.3.5
* Author: Jumpstart Themes
* Website: http://jumpstartthemes.com
*
* ====================================================== */
body {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', 'Trebuchet MS', arial, sans-serif;
  font-size: 13px;
}
p {
  line-height: 1.7em;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: .75em;
  font-family: 'Oswald', 'Trebuchet MS', 'Open Sans', arial, sans-serif;
}
label {
  font-weight: 600;
}
.semibold {
  font-weight: 600;
}
.text-primary {
  color: #d74b4b;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #c42b2b;
}
.text-secondary {
  color: #6685a4;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #516c87;
}
.text-tertiary {
  color: #afafaf;
}
a.text-tertiary:hover,
a.text-tertiary:focus {
  color: #969696;
}
.text-success {
  color: #5cb85c;
}
a.text-success:hover,
a.text-success:focus {
  color: #449d44;
}
.text-warning {
  color: #f0ad4e;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #ec971f;
}
.text-danger {
  color: #d9534f;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #c9302c;
}
.text-info {
  color: #5bc0de;
}
a.text-info:hover,
a.text-info:focus {
  color: #31b0d5;
}
.text-sm {
  font-size: 11px;
}
.text-md {
  font-size: 13px;
}
.text-lg {
  font-size: 18px;
}
.text-xl {
  font-size: 24px;
}
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-facebook,
.btn-twitter {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:active,
.btn-secondary:active,
.btn-tertiary:active,
.btn-facebook:active,
.btn-twitter:active,
.btn-primary.active,
.btn-secondary.active,
.btn-tertiary.active,
.btn-facebook.active,
.btn-twitter.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-primary {
  color: #ffffff;
  background-color: #d74b4b;
  border-color: #cc2d2d;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: #c42b2b;
  border-color: #641616;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #c42b2b;
  border-color: #9a2222;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #c42b2b;
  border-color: #9a2222;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #ffffff;
  background-color: #a62525;
  border-color: #641616;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #d74b4b;
  border-color: #cc2d2d;
}
.btn-primary .badge {
  color: #d74b4b;
  background-color: #ffffff;
}
.btn-secondary {
  color: #ffffff;
  background-color: #6685a4;
  border-color: #54718d;
}
.btn-secondary:focus,
.btn-secondary.focus {
  color: #ffffff;
  background-color: #516c87;
  border-color: #25313e;
}
.btn-secondary:hover {
  color: #ffffff;
  background-color: #516c87;
  border-color: #3d5267;
}
.btn-secondary:active,
.btn-secondary.active,
.open > .dropdown-toggle.btn-secondary {
  color: #ffffff;
  background-color: #516c87;
  border-color: #3d5267;
}
.btn-secondary:active:hover,
.btn-secondary.active:hover,
.open > .dropdown-toggle.btn-secondary:hover,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.open > .dropdown-toggle.btn-secondary:focus,
.btn-secondary:active.focus,
.btn-secondary.active.focus,
.open > .dropdown-toggle.btn-secondary.focus {
  color: #ffffff;
  background-color: #435a71;
  border-color: #25313e;
}
.btn-secondary:active,
.btn-secondary.active,
.open > .dropdown-toggle.btn-secondary {
  background-image: none;
}
.btn-secondary.disabled,
.btn-secondary[disabled],
fieldset[disabled] .btn-secondary,
.btn-secondary.disabled:hover,
.btn-secondary[disabled]:hover,
fieldset[disabled] .btn-secondary:hover,
.btn-secondary.disabled:focus,
.btn-secondary[disabled]:focus,
fieldset[disabled] .btn-secondary:focus,
.btn-secondary.disabled.focus,
.btn-secondary[disabled].focus,
fieldset[disabled] .btn-secondary.focus,
.btn-secondary.disabled:active,
.btn-secondary[disabled]:active,
fieldset[disabled] .btn-secondary:active,
.btn-secondary.disabled.active,
.btn-secondary[disabled].active,
fieldset[disabled] .btn-secondary.active {
  background-color: #6685a4;
  border-color: #54718d;
}
.btn-secondary .badge {
  color: #6685a4;
  background-color: #ffffff;
}
.btn-tertiary {
  color: #ffffff;
  background-color: #bcbcbc;
  border-color: #a8a8a8;
}
.btn-tertiary:focus,
.btn-tertiary.focus {
  color: #ffffff;
  background-color: #a3a3a3;
  border-color: #686868;
}
.btn-tertiary:hover {
  color: #ffffff;
  background-color: #a3a3a3;
  border-color: #898989;
}
.btn-tertiary:active,
.btn-tertiary.active,
.open > .dropdown-toggle.btn-tertiary {
  color: #ffffff;
  background-color: #a3a3a3;
  border-color: #898989;
}
.btn-tertiary:active:hover,
.btn-tertiary.active:hover,
.open > .dropdown-toggle.btn-tertiary:hover,
.btn-tertiary:active:focus,
.btn-tertiary.active:focus,
.open > .dropdown-toggle.btn-tertiary:focus,
.btn-tertiary:active.focus,
.btn-tertiary.active.focus,
.open > .dropdown-toggle.btn-tertiary.focus {
  color: #ffffff;
  background-color: #919191;
  border-color: #686868;
}
.btn-tertiary:active,
.btn-tertiary.active,
.open > .dropdown-toggle.btn-tertiary {
  background-image: none;
}
.btn-tertiary.disabled,
.btn-tertiary[disabled],
fieldset[disabled] .btn-tertiary,
.btn-tertiary.disabled:hover,
.btn-tertiary[disabled]:hover,
fieldset[disabled] .btn-tertiary:hover,
.btn-tertiary.disabled:focus,
.btn-tertiary[disabled]:focus,
fieldset[disabled] .btn-tertiary:focus,
.btn-tertiary.disabled.focus,
.btn-tertiary[disabled].focus,
fieldset[disabled] .btn-tertiary.focus,
.btn-tertiary.disabled:active,
.btn-tertiary[disabled]:active,
fieldset[disabled] .btn-tertiary:active,
.btn-tertiary.disabled.active,
.btn-tertiary[disabled].active,
fieldset[disabled] .btn-tertiary.active {
  background-color: #bcbcbc;
  border-color: #a8a8a8;
}
.btn-tertiary .badge {
  color: #bcbcbc;
  background-color: #ffffff;
}
.btn-inverse {
  color: #ffffff;
  background-color: #354b5e;
  border-color: #263644;
}
.btn-inverse:focus,
.btn-inverse.focus {
  color: #ffffff;
  background-color: #23313d;
  border-color: #000000;
}
.btn-inverse:hover {
  color: #ffffff;
  background-color: #23313d;
  border-color: #10171d;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  color: #ffffff;
  background-color: #23313d;
  border-color: #10171d;
}
.btn-inverse:active:hover,
.btn-inverse.active:hover,
.open > .dropdown-toggle.btn-inverse:hover,
.btn-inverse:active:focus,
.btn-inverse.active:focus,
.open > .dropdown-toggle.btn-inverse:focus,
.btn-inverse:active.focus,
.btn-inverse.active.focus,
.open > .dropdown-toggle.btn-inverse.focus {
  color: #ffffff;
  background-color: #161f27;
  border-color: #000000;
}
.btn-inverse:active,
.btn-inverse.active,
.open > .dropdown-toggle.btn-inverse {
  background-image: none;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled.focus,
.btn-inverse[disabled].focus,
fieldset[disabled] .btn-inverse.focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  background-color: #354b5e;
  border-color: #263644;
}
.btn-inverse .badge {
  color: #354b5e;
  background-color: #ffffff;
}
.btn-facebook {
  color: #ffffff;
  background-color: #3b5998;
  border-color: #30487b;
}
.btn-facebook:focus,
.btn-facebook.focus {
  color: #ffffff;
  background-color: #2d4373;
  border-color: #0c121f;
}
.btn-facebook:hover {
  color: #ffffff;
  background-color: #2d4373;
  border-color: #1e2e4f;
}
.btn-facebook:active,
.btn-facebook.active,
.open > .dropdown-toggle.btn-facebook {
  color: #ffffff;
  background-color: #2d4373;
  border-color: #1e2e4f;
}
.btn-facebook:active:hover,
.btn-facebook.active:hover,
.open > .dropdown-toggle.btn-facebook:hover,
.btn-facebook:active:focus,
.btn-facebook.active:focus,
.open > .dropdown-toggle.btn-facebook:focus,
.btn-facebook:active.focus,
.btn-facebook.active.focus,
.open > .dropdown-toggle.btn-facebook.focus {
  color: #ffffff;
  background-color: #23345a;
  border-color: #0c121f;
}
.btn-facebook:active,
.btn-facebook.active,
.open > .dropdown-toggle.btn-facebook {
  background-image: none;
}
.btn-facebook.disabled,
.btn-facebook[disabled],
fieldset[disabled] .btn-facebook,
.btn-facebook.disabled:hover,
.btn-facebook[disabled]:hover,
fieldset[disabled] .btn-facebook:hover,
.btn-facebook.disabled:focus,
.btn-facebook[disabled]:focus,
fieldset[disabled] .btn-facebook:focus,
.btn-facebook.disabled.focus,
.btn-facebook[disabled].focus,
fieldset[disabled] .btn-facebook.focus,
.btn-facebook.disabled:active,
.btn-facebook[disabled]:active,
fieldset[disabled] .btn-facebook:active,
.btn-facebook.disabled.active,
.btn-facebook[disabled].active,
fieldset[disabled] .btn-facebook.active {
  background-color: #3b5998;
  border-color: #30487b;
}
.btn-facebook .badge {
  color: #3b5998;
  background-color: #ffffff;
}
.btn-twitter {
  color: #ffffff;
  background-color: #00acee;
  border-color: #008fc5;
}
.btn-twitter:focus,
.btn-twitter.focus {
  color: #ffffff;
  background-color: #0087bb;
  border-color: #003246;
}
.btn-twitter:hover {
  color: #ffffff;
  background-color: #0087bb;
  border-color: #006288;
}
.btn-twitter:active,
.btn-twitter.active,
.open > .dropdown-toggle.btn-twitter {
  color: #ffffff;
  background-color: #0087bb;
  border-color: #006288;
}
.btn-twitter:active:hover,
.btn-twitter.active:hover,
.open > .dropdown-toggle.btn-twitter:hover,
.btn-twitter:active:focus,
.btn-twitter.active:focus,
.open > .dropdown-toggle.btn-twitter:focus,
.btn-twitter:active.focus,
.btn-twitter.active.focus,
.open > .dropdown-toggle.btn-twitter.focus {
  color: #ffffff;
  background-color: #006d97;
  border-color: #003246;
}
.btn-twitter:active,
.btn-twitter.active,
.open > .dropdown-toggle.btn-twitter {
  background-image: none;
}
.btn-twitter.disabled,
.btn-twitter[disabled],
fieldset[disabled] .btn-twitter,
.btn-twitter.disabled:hover,
.btn-twitter[disabled]:hover,
fieldset[disabled] .btn-twitter:hover,
.btn-twitter.disabled:focus,
.btn-twitter[disabled]:focus,
fieldset[disabled] .btn-twitter:focus,
.btn-twitter.disabled.focus,
.btn-twitter[disabled].focus,
fieldset[disabled] .btn-twitter.focus,
.btn-twitter.disabled:active,
.btn-twitter[disabled]:active,
fieldset[disabled] .btn-twitter:active,
.btn-twitter.disabled.active,
.btn-twitter[disabled].active,
fieldset[disabled] .btn-twitter.active {
  background-color: #00acee;
  border-color: #008fc5;
}
.btn-twitter .badge {
  color: #00acee;
  background-color: #ffffff;
}
.btn-transparent {
  color: #ffffff;
  background-color: transparent;
  border-color: #ffffff;
  border-width: 2px;
}
.btn-transparent:focus,
.btn-transparent.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bfbfbf;
}
.btn-transparent:hover {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #e0e0e0;
}
.btn-transparent:active,
.btn-transparent.active,
.open > .dropdown-toggle.btn-transparent {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #e0e0e0;
}
.btn-transparent:active:hover,
.btn-transparent.active:hover,
.open > .dropdown-toggle.btn-transparent:hover,
.btn-transparent:active:focus,
.btn-transparent.active:focus,
.open > .dropdown-toggle.btn-transparent:focus,
.btn-transparent:active.focus,
.btn-transparent.active.focus,
.open > .dropdown-toggle.btn-transparent.focus {
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0);
  border-color: #bfbfbf;
}
.btn-transparent:active,
.btn-transparent.active,
.open > .dropdown-toggle.btn-transparent {
  background-image: none;
}
.btn-transparent.disabled,
.btn-transparent[disabled],
fieldset[disabled] .btn-transparent,
.btn-transparent.disabled:hover,
.btn-transparent[disabled]:hover,
fieldset[disabled] .btn-transparent:hover,
.btn-transparent.disabled:focus,
.btn-transparent[disabled]:focus,
fieldset[disabled] .btn-transparent:focus,
.btn-transparent.disabled.focus,
.btn-transparent[disabled].focus,
fieldset[disabled] .btn-transparent.focus,
.btn-transparent.disabled:active,
.btn-transparent[disabled]:active,
fieldset[disabled] .btn-transparent:active,
.btn-transparent.disabled.active,
.btn-transparent[disabled].active,
fieldset[disabled] .btn-transparent.active {
  background-color: transparent;
  border-color: #ffffff;
}
.btn-transparent .badge {
  color: transparent;
  background-color: #ffffff;
}
.btn-transparent:hover {
  background: rgba(0, 0, 0, 0.1);
}
.btn-jumbo {
  padding: 13px 20px;
  font-size: 22px;
  line-height: 1.33;
  border-radius: 6px;
}
.badge {
  font-weight: 600;
}
.badge-primary {
  background-color: #d74b4b;
  color: #ffffff;
}
.badge-secondary {
  background-color: #6685a4;
  color: #ffffff;
}
.badge-tertiary {
  background-color: #bcbcbc;
  color: #ffffff;
}
.label {
  font-weight: 600;
}
.label-primary {
  background-color: #d74b4b;
  color: #ffffff;
}
.label-secondary {
  background-color: #6685a4;
  color: #ffffff;
}
.label-tertiary {
  background-color: #bcbcbc;
  color: #ffffff;
}
.progress {
  background-color: #f5f5f5;
}
.progress-bar-primary {
  background-color: #d74b4b;
}
.progress-bar-secondary {
  background-color: #6685a4;
}
.progress-bar-tertiary {
  background-color: #bcbcbc;
}
.progress-sm {
  height: 12px;
}
.progress-lg {
  height: 28px;
}
.popover-title {
  font-size: 15px;
}
.popover-content {
  font-family: 'Open Sans', 'Trebuchet MS', arial, sans-serif;
  font-size: 13px;
}
.alert {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.alert strong {
  font-weight: 600;
}
.alert-success {
  background-color: #5cb85c;
  border-color: #5cb85c;
  color: #ffffff;
}
.alert-success hr {
  border-top-color: #4cae4c;
}
.alert-success .alert-link {
  color: #e6e6e6;
}
.alert-info {
  background-color: #5bc0de;
  border-color: #5bc0de;
  color: #ffffff;
}
.alert-info hr {
  border-top-color: #46b8da;
}
.alert-info .alert-link {
  color: #e6e6e6;
}
.alert-warning {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
  color: #ffffff;
}
.alert-warning hr {
  border-top-color: #eea236;
}
.alert-warning .alert-link {
  color: #e6e6e6;
}
.alert-danger {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #ffffff;
}
.alert-danger hr {
  border-top-color: #d43f3a;
}
.alert-danger .alert-link {
  color: #e6e6e6;
}
.form-group {
  margin-bottom: 25px;
}
.form-control {
  padding-right: 6px;
  padding-left: 6px;
  box-shadow: none;
}
.control-label {
  font-weight: 600;
}
.checkbox,
.radio {
  margin-bottom: 15px;
}
.checkbox-inline,
.radio-inline {
  margin-right: 20px;
  margin-bottom: 20px;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-left: 0;
}
.form-horizontal .control-label {
  position: relative;
  top: 3px;
  text-align: right;
}
.btn-primary .caret,
.btn-secondary .caret,
.btn-tertiary .caret,
.btn-facebook .caret,
.btn-twitter .caret {
  border-top-color: #fff;
}
.dropup .btn-primary .caret,
.dropup .btn-secondary .caret,
.dropup .btn-tertiary .caret,
.dropup .btn-facebook .caret,
.dropup .btn-twitter .caret {
  border-bottom-color: #fff;
}
.dropdown-menu > li {
  margin-right: .5em;
  margin-left: .5em;
}
.dropdown-menu > li > a {
  padding: 6px 12px;
  font-size: 13px;
  outline: none;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  background-color: #ededed;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #444;
  background-color: #dbdbdb;
}
.dropdown-header {
  padding-right: 12px;
  padding-left: 12px;
  font-size: 11px;
  text-transform: uppercase;
}
.dropdown-icon {
  width: 16px;
  margin-right: 8px;
  color: #444;
  font-size: 15px;
  text-align: center;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  display: none;
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu:hover > .dropdown-menu:before,
.dropdown-submenu:hover > .dropdown-menu:after {
  display: none;
}
.navbar .dropdown-submenu > .dropdown-menu,
.mainnav .dropdown-submenu > .dropdown-menu {
  display: block;
  margin-top: -6px;
  margin-left: 20px;
}
@media (min-width: 768px) {
  .navbar .dropdown-submenu > .dropdown-menu,
  .mainnav .dropdown-submenu > .dropdown-menu {
    display: none;
    margin-left: -1px;
  }
  .navbar .dropdown-submenu:hover > .dropdown-menu,
  .mainnav .dropdown-submenu:hover > .dropdown-menu {
    display: block;
  }
  .dropdown-submenu > a:after {
    content: "";
    display: block;
    float: right;
    width: 0;
    height: 0;
    margin-top: 5px;
    margin-right: -10px;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #cccccc;
  }
  .dropdown-submenu:hover > a:after {
    border-left-color: #d74b4b;
  }
}
.table-bordered thead tr th,
.table-bordered tfoot tr th {
  color: #444;
  font-size: 12px;
  font-weight: 800;
  background-color: #fff;
  border-bottom-width: 1px;
  vertical-align: middle;
  font-family: 'Oswald', 'Trebuchet MS', 'Open Sans', arial, sans-serif;
  font-weight: 600;
  font-size: 14px;
}
.pagination > li {
  display: inline-block;
  margin: 0 3px;
}
.pagination > li:first-child {
  margin-left: 0;
}
.pagination > li:last-child {
  margin-right: 0;
}
.pagination > li > a,
.pagination > li > span {
  color: #222222;
  background-color: transparent;
  border-color: #dddddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  color: #2a6496;
  background-color: #eeeeee;
  border-color: #dddddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  color: #ffffff;
  background-color: #d74b4b;
  border-color: #af2727;
  cursor: default;
}
.pager > li > a {
  color: #222;
}
.tab-content.stacked-content > .tab-pane {
  padding-top: 0;
}
.tab-pane {
  position: relative;
}
.nav-tabs,
.nav-pills {
  margin-bottom: 1.5em;
}
.nav-tabs .fa-caret-down,
.nav-pills .fa-caret-down {
  margin-left: 3px;
}
.nav-pills.nav-stacked > li {
  margin-right: 0;
  margin-bottom: .5em;
}
.nav-pills.nav-stacked > li > a {
  padding: 10px 15px;
}
.nav-pills > li {
  margin-right: 6px;
}
.nav-pills > li > a {
  padding: 5px 12px;
  color: #444444;
  background-color: #eeeeee;
  border: 1px solid transparent;
}
.nav-pills > li > a:hover,
.nav-pills > li > a:focus {
  color: #444444;
  background-color: #e4e4e4;
  border-color: transparent;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #ffffff;
  background-color: #d74b4b;
  border-color: transparent;
}
.nav-tabs > li > a {
  color: #444444;
  background-color: transparent;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  color: #444444;
  background-color: #eeeeee;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #d74b4b;
  background-color: #ffffff;
  font-weight: 600;
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  color: #444444;
  background-color: #eeeeee;
  border-color: #d74b4b;
}
.modal-backdrop {
  background-color: #ffffff;
}
.modal-backdrop.in {
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.lt-ie9 .modal-content {
  border-color: #ccc;
}
.modal-styled .modal-header {
  color: #ffffff;
  background-color: #2b3d4c;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.modal-styled .modal-header .close {
  color: #7797b2;
  background-color: transparent;
  text-shadow: none;
  opacity: 1;
  filter: alpha(opacity=100);
}
.modal-styled .modal-header .close:hover {
  color: #ffffff;
  background-color: transparent;
}
.modal-styled .modal-title {
  font-weight: 300;
}
.modal-styled .modal-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
}
.modal-styled .modal-footer {
  color: #555555;
  background-color: #f1f1f1;
  border-top-color: #dddddd;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.thumbnail-view {
  position: relative;
  display: inline-block;
  overflow: hidden;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}
.thumbnail-view img {
  max-width: 100%;
}
.thumbnail-view .thumbnail-view-hover {
  display: block;
  position: absolute;
  right: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background: #111;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transition: all 0.25s ease-in-out;
}
.thumbnail-view .thumbnail-view-hover i,
.thumbnail-view .thumbnail-view-hover-lg i {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 48px;
  height: 48px;
  margin-left: -24px;
  margin-top: -24px;
  color: #ddd;
  color: rgba(255, 255, 255, 0.6);
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  background: transparent;
  border: 3px solid #fff;
  border-color: rgba(255, 255, 255, 0.6);
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.thumbnail-view .thumbnail-view-hover-lg i {
  width: 72px;
  height: 72px;
  margin-left: -36px;
  margin-top: -36px;
  font-size: 48px;
  line-height: 72px;
}
.thumbnail-view:hover .thumbnail-view-hover {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  -o-transform: translateY(0%);
}
.thumbnail-footer {
  padding: 7px 10px;
  border-top: 1px dotted #ccc;
}
.thumbnail-footer:before,
.thumbnail-footer:after {
  content: " ";
  display: table;
}
.thumbnail-footer:after {
  clear: both;
}
.thumbnail-footer a {
  color: #999;
  font-size: 12px;
}
.thumbnail-footer a:hover {
  text-decoration: none;
  color: #d74b4b;
}
.thumbnail-footer .pull-left a {
  padding: 0 12px 0 0;
}
.thumbnail-footer .pull-right a {
  padding: 0 0 0 12px;
}
.thumbnail-table .thumbnail {
  margin-bottom: 0;
}
.accordion-panel .panel {
  border-color: #dddddd;
}
.accordion-panel .panel-heading {
  padding: 0;
}
.accordion-panel .accordion-toggle {
  display: block;
  padding: 10px 15px;
  font-size: 14px;
  color: #555555;
  background-color: #f5f5f5;
  cursor: pointer;
}
.accordion-panel .accordion-toggle:hover,
.accordion-panel .accordion-toggle:focus {
  text-decoration: none;
  outline: none !important;
}
.accordion-panel .panel.is-open {
  border-color: #dddddd;
}
.accordion-panel .panel.is-open .accordion-toggle {
  color: #ffffff;
  background-color: #354b5e;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.accordion-simple .panel + .panel {
  margin-top: 0;
}
.accordion-simple .panel-heading {
  padding-right: 0;
  padding-left: 0;
}
.accordion-simple .panel-body {
  padding-left: 25px;
}
.accordion-simple .panel-title {
  position: relative;
  padding-left: 0;
  color: #444444;
  font-family: 'Open Sans', 'Trebuchet MS', arial, sans-serif;
  font-size: 14px;
  line-height: 1.75em;
  letter-spacing: -1px;
}
.accordion-simple .accordion-caret:before {
  content: "\f196";
  top: 1px;
  left: 0;
  margin-right: 8px;
}
.accordion-simple .panel.is-open .accordion-caret:before {
  content: "\f146";
}
.accordion-caret:before {
  content: "\f0da";
  display: inline-block;
  position: relative;
  left: 2px;
  width: 16px;
  height: 16px;
  margin-right: 3px;
  color: #d74b4b;
  font-size: 1.25em;
}
.panel.is-open .panel-title {
  color: #444444;
}
.panel.is-open .accordion-caret:before {
  content: "\f0d7";
  left: 0;
  color: #d74b4b;
}
.list-group .list-group-item .list-group-chevron {
  float: right;
  margin-top: 5px;
  color: #bbb;
}
.list-group .list-group-item .badge {
  margin-top: 2px;
}
.list-group .list-group-item .list-group-chevron + .badge {
  margin-right: 10px;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.container {
  position: relative;
}
.content {
  position: relative;
  margin-bottom: 2em;
}
.layout {
  margin-top: -40px;
  margin-left: -15px;
  margin-right: -15px;
}
.layout-main {
  padding-top: 40px;
  padding-bottom: 26px;
}
.layout-sidebar {
  padding-top: 40px;
  padding-bottom: 60px;
}
.layout-main-left .layout-main {
  padding-right: 30px;
  padding-left: 15px;
}
.layout-main-left .layout-sidebar {
  padding-right: 15px;
  padding-left: 30px;
  border-left: 1px solid #ccc;
  box-shadow: inset 8px 0px 15px -10px rgba(0, 0, 0, 0.2);
}
.layout-main-right .layout-main {
  padding-right: 15px;
  padding-left: 30px;
}
.layout-main-right .layout-sidebar {
  padding-right: 30px;
  padding-left: 15px;
  border-right: 1px solid #ccc;
  box-shadow: inset -8px 0 15px -10px rgba(0, 0, 0, 0.2);
}
/* !Nav Layout */
/* ----------------------------------------------- */
.nav-layout-sidebar-skip {
  display: none;
  margin-bottom: 1em;
  font-size: 12px;
  text-align: center;
}
.nav-layout-sidebar {
  position: relative;
  z-index: 101;
  margin: 0 0 2em;
  border-top: 1px solid #e5e5e5;
}
.nav-layout-sidebar > li {
  padding: 0;
  margin: 0;
}
.nav-layout-sidebar > li > a {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #666;
  border-bottom: 1px solid #e5e5e5;
  outline: none;
}
.nav-layout-sidebar > li > a .fa {
  position: relative;
  top: 1px;
  width: 1em;
  margin-right: 4px;
  font-size: 1.25em;
  text-align: center;
}
.nav-layout-sidebar > li > a:hover {
  background-color: #f4f4f4;
}
.nav-layout-sidebar > .active > a,
.nav-layout-sidebar > .active > a:focus,
.nav-layout-sidebar > .active > a:hover {
  color: #d74b4b;
  font-weight: 600;
  background-color: #fff;
}
.nav-layout-sidebar > li + li {
  margin-top: 0;
}
.layout-main-left .nav-layout-sidebar {
  margin-right: 0;
  margin-left: -31px;
}
.layout-main-left .nav-layout-sidebar > li {
  margin: 0 0 0 1px;
}
.layout-main-left .nav-layout-sidebar > .active {
  margin-left: 0;
}
.layout-main-right .nav-layout-sidebar {
  margin-left: 0;
  margin-right: -31px;
}
.layout-main-right .nav-layout-sidebar > li {
  margin: 0 1px 0 0;
}
.layout-main-right .nav-layout-sidebar > .active {
  margin-right: 0;
}
/* !Responsive Layout */
/* ----------------------------------------------- */
@media (max-width: 1200px) {
  .layout-stack-lg {
    margin-top: 0;
  }
  .layout-stack-lg .layout-main,
  .layout-stack-lg .layout-sidebar {
    padding-top: 0;
  }
  .layout-stack-lg .layout-main {
    padding-right: 15px;
    padding-left: 15px;
  }
  .layout-stack-lg .layout-sidebar {
    padding-right: 15px;
    padding-left: 15px;
    border-right: none;
    border-left: none;
    box-shadow: none;
  }
  .layout-stack-lg .nav-layout-sidebar-skip {
    display: block;
  }
  .layout-stack-lg .nav-layout-sidebar {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 992px) {
  .layout-stack-md {
    margin-top: 0;
  }
  .layout-stack-md .layout-main,
  .layout-stack-md .layout-sidebar {
    padding-top: 0;
  }
  .layout-stack-md .layout-main {
    padding-right: 15px;
    padding-left: 15px;
  }
  .layout-stack-md .layout-sidebar {
    padding-right: 15px;
    padding-left: 15px;
    border-right: none;
    border-left: none;
    box-shadow: none;
  }
  .layout-stack-md .nav-layout-sidebar-skip {
    display: block;
  }
  .layout-stack-md .nav-layout-sidebar {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 768px) {
  .layout-stack-sm {
    margin-top: 0;
  }
  .layout-stack-sm .layout-main,
  .layout-stack-sm .layout-sidebar {
    padding-top: 0;
  }
  .layout-stack-sm .layout-main {
    padding-right: 15px;
    padding-left: 15px;
  }
  .layout-stack-sm .layout-sidebar {
    padding-right: 15px;
    padding-left: 15px;
    border-right: none;
    border-left: none;
    box-shadow: none;
  }
  .layout-stack-sm .nav-layout-sidebar-skip {
    display: block;
  }
  .layout-stack-sm .nav-layout-sidebar {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .layout-stack-xs {
    margin-top: 0;
  }
  .layout-stack-xs .layout-main,
  .layout-stack-xs .layout-sidebar {
    padding-top: 0;
  }
  .layout-stack-xs .layout-main {
    padding-right: 15px;
    padding-left: 15px;
  }
  .layout-stack-xs .layout-sidebar {
    padding-right: 15px;
    padding-left: 15px;
    border-right: none;
    border-left: none;
    box-shadow: none;
  }
  .layout-stack-xs .nav-layout-sidebar-skip {
    display: block;
  }
  .layout-stack-xs .nav-layout-sidebar {
    margin-right: 0;
    margin-left: 0;
  }
}
.portlet {
  position: relative;
  padding: 0;
  margin: 0 0 3em;
}
.portlet-header {
  padding: 0;
  margin: 0;
}
.portlet-title u {
  text-decoration: none;
}
.portlet-icon {
  position: relative;
  top: -1px;
  margin-right: 10px;
  font-size: 16px;
}
.portlet-body {
  padding: 0;
  margin: 0;
}
.portlet-default .portlet-title {
  position: relative;
  margin-bottom: 30px;
}
.portlet-default .portlet-title:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  z-index: 9;
  background: #e6e6e6;
}
.portlet-default .portlet-title:after {
  content: "";
  display: block;
  position: relative;
  z-index: 10;
  width: 80px;
  height: 1px;
  margin: 20px 0 0;
  background: #6685a4;
}
.portlet-boxed .portlet-header {
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.portlet-boxed .portlet-title {
  margin-bottom: 0;
  font-size: 16px;
}
.portlet-boxed .portlet-title u {
  text-decoration: none;
}
.portlet-boxed .portlet-body {
  padding: 20px 15px;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.portlet-table .portlet-body {
  padding: 0;
}
.portlet-table .table-bordered {
  margin-top: -2px !important;
  margin-bottom: -2px !important;
  border-right: none;
  border-left: none;
}
.portlet-table .dataTables_filter,
.portlet-table .dataTables_paginate {
  float: right;
  padding-right: 10px;
}
.portlet-table .dataTables_info,
.portlet-table .dataTables_length {
  float: left;
  padding-left: 10px;
}
.portlet-table .dataTables_filter,
.portlet-table .dataTables_length {
  padding-top: 13px;
  padding-bottom: 10px;
}
.portlet-table .dataTables_paginate,
.portlet-table .dataTables_info {
  padding-top: 10px;
  padding-bottom: 10px;
}
.portlet-table .dataTables_info {
  padding-top: 20px;
}
.portlet-boxed .portlet-header {
  background-color: #f4f4f4;
}
.portlet-boxed .portlet-title {
  color: #444444;
}
.portlet-boxed.portlet-inverse .portlet-header {
  background-color: #354b5e;
}
.portlet-boxed.portlet-inverse .portlet-title {
  color: #ffffff;
}
.portlet-boxed.portlet-danger .portlet-header {
  background-color: #db5b57;
}
.portlet-boxed.portlet-danger .portlet-title {
  color: #ffffff;
}
.portlet-boxed.portlet-warning .portlet-header {
  background-color: #f1b157;
}
.portlet-boxed.portlet-warning .portlet-title {
  color: #ffffff;
}
.portlet-boxed.portlet-success .portlet-header {
  background-color: #5cb85c;
}
.portlet-boxed.portlet-success .portlet-title {
  color: #ffffff;
}
.portlet-boxed.portlet-info .portlet-header {
  background-color: #5bc0de;
}
.portlet-boxed.portlet-info .portlet-title {
  color: #ffffff;
}
.portlet-boxed.portlet-primary .portlet-header {
  background-color: #d74b4b;
}
.portlet-boxed.portlet-primary .portlet-title {
  color: #ffffff;
}
.portlet-boxed.portlet-secondary .portlet-header {
  background-color: #6685a4;
}
.portlet-boxed.portlet-secondary .portlet-title {
  color: #ffffff;
}
.portlet-boxed.portlet-tertiary .portlet-header {
  background-color: #bcbcbc;
}
.portlet-boxed.portlet-tertiary .portlet-title {
  color: #ffffff;
}
.icons-list {
  padding: 0;
  margin: 0 0 10px;
  list-style: none;
}
.icons-list > li {
  position: relative;
  padding-left: 1.75em;
  margin-bottom: 1em;
  line-height: 1.65em;
}
.icons-list > li > .icon-li {
  position: absolute;
  top: .25em;
  left: 0;
  width: 1em;
  font-size: 1.25em;
  text-align: center;
}
.parsley-form .form-group {
  position: relative;
}
.parsley-form .form-group .parsley-success .parsley-error-list {
  display: none !important;
}
.parsley-form .form-group .parsley-error-list {
  position: relative;
  top: 0;
  display: none !important;
  padding: 2px 10px;
  margin: 0;
  list-style: none;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  background: #d9534f;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.parsley-form .form-group .parsley-error-list::after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #d9534f;
  position: absolute;
  top: -6px;
  left: 3px;
}
.parsley-form .form-group .parsley-error-list.filled {
  display: inline-block !important;
}
.parsley-form.form-horizontal .form-group span.error {
  margin-left: 1.5em;
  margin-left: 15px;
}
#back-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1001;
  width: 30px;
  height: 30px;
  display: block;
  color: #ffffff;
  font-size: 13px;
  text-align: center;
  line-height: 30px;
  background-color: rgba(43, 61, 76, 0.85);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
  transition: background 0.25s linear;
}
#back-to-top:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: rgba(215, 75, 75, 0.85);
}
#back-to-top i {
  color: #fff;
}
@media (min-width: 768px) {
  #back-to-top {
    width: 45px;
    height: 45px;
    font-size: 16px;
    line-height: 45px;
  }
}
.notifications-list > li {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 2.5em;
  margin-bottom: 0;
  font-size: 13px;
  border-bottom: 1px solid #ddd;
}
.notifications-list > li > .icon-li {
  top: 10px;
  font-size: 1.40em;
}
.pricing-plan {
  position: relative;
  display: block;
  vertical-align: top;
  width: 100%;
  padding: 15px 15px;
  margin-bottom: 4em;
  text-align: center;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pricing-header {
  padding: 15px;
  margin: -25px -15px 15px;
  color: #87a3bc;
  background-color: #344a5c;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.pricing-plan-help {
  border-bottom: 1px dotted #000;
  cursor: help;
}
.pricing-plan-help strong.ui-popover {
  border-bottom: 1px dotted #000;
}
.pricing-plan-label {
  padding-right: 12px;
  padding-left: 12px;
  margin-bottom: 0;
  font-size: 13px;
}
.pricing-plan-title {
  margin-top: 0;
  margin-bottom: 6px;
  color: #ffffff;
  font-size: 21px;
  font-weight: 400;
  text-transform: uppercase;
}
.pricing-plan-price {
  display: block;
  padding: 10px;
  margin: -15px -15px 0;
  color: #555555;
  background-color: #f9f9f9;
  border-bottom: 1px solid #dddddd;
}
.pricing-plan-amount {
  color: #46a546;
  font-size: 24px;
  font-weight: 400;
}
.pricing-plan-details {
  padding: .5em 0 0;
  margin: 0 0 1em;
}
.pricing-plan-details li {
  padding-top: .5em;
  padding-bottom: .5em;
  list-style: none;
  border-bottom: 1px solid #e8e8e8;
}
.pricing-plan hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.pricing-plan-current,
.pricing-plan-current:hover {
  color: #333;
  font-weight: 800;
  text-decoration: none;
  cursor: default;
}
.pricing-plan-ribbon,
.pricing-plan-ribbon-primary,
.pricing-plan-ribbon-secondary,
.pricing-plan-ribbon-tertiary,
.pricing-plan-ribbon-current {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 15px;
  width: 28px;
  padding: 8px 0 12px;
  color: #fff;
  background-color: #777;
}
.pricing-plan-ribbon:before,
.pricing-plan-ribbon-primary:before,
.pricing-plan-ribbon-secondary:before,
.pricing-plan-ribbon-tertiary:before,
.pricing-plan-ribbon-current:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 0;
  border-bottom: 6px solid #344a5c;
  border-right: 14px solid transparent;
  border-left: 14px solid transparent;
}
.pricing-plan-ribbon-primary {
  background-color: #d74b4b;
}
.pricing-plan-ribbon-secondary {
  background-color: #6685a4;
}
.pricing-plan-ribbon-tertiary {
  background-color: #bcbcbc;
}
.pricing-plan-ribbon-current {
  background-color: #3d576d;
  color: #d74b4b;
}
@media (min-width: 768px) {
  .pricing-plan {
    max-width: 300px;
    padding-top: 25px;
    padding-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2em;
  }
  .pricing-plan hr {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .pricing-plan-amount {
    font-size: 32px;
  }
  .pricing-plan-details {
    margin: 0 0 2em;
  }
  .pricing-plan-details li {
    padding-top: 1.25em;
    padding-bottom: 1.25em;
  }
}
.pricing-table {
  margin-bottom: 3em;
}
.pricing-table-head {
  height: 70px;
  padding: 15px 5px;
  text-align: center;
  background-color: #344a5c;
  border-bottom: solid 1px #dddddd;
}
.pricing-table-head-title {
  margin: 0 0 4px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
}
.pricing-table-head-byline {
  display: block;
  color: #87a3bc;
  font-family: 'Open Sans', 'Trebuchet MS', arial, sans-serif;
  font-size: 13px;
}
.pricing-table-amount {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 50px;
  margin-bottom: 0;
  color: #444444;
  font-family: 'Open Sans', 'Trebuchet MS', arial, sans-serif;
  font-size: 15px;
  font-weight: 300;
  line-height: 50px;
  text-align: center;
  background-color: #ffffff;
  border-left: solid 1px #dddddd;
}
.pricing-table-amount i {
  font-style: normal;
  font-size: 22px;
  font-weight: 600;
  line-height: 0px;
}
.pricing-table-index {
  margin-top: 120px;
  font-weight: 600;
}
.pricing-table-index .pricing-table-content {
  text-align: right;
}
.pricing-table-content {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style-type: none;
  border-top: 1px solid #dddddd;
  border-left: solid 1px #dddddd;
}
.pricing-table-content > li {
  padding: 12px 10px;
  margin: 0;
  background-color: #ffffff;
  border-bottom: solid 1px #dddddd;
}
.pricing-table-content li:nth-child(2n+1) {
  background-color: #f9f9f9;
}
.pricing-table-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid #dddddd;
  border-left: solid 1px #dddddd;
}
.pricing-table-footer .btn {
  padding-right: 20px;
  padding-left: 20px;
}
/* Fix the missing border on the right side */
.pricing-tables .pricing-col:last-child .pricing-table-amount,
.pricing-tables .pricing-col:last-child .pricing-table-content,
.pricing-tables .pricing-col:last-child .pricing-table-footer {
  border-right: solid 1px #dddddd;
}
.pricing-tables .row .pricing-col:first-child .pricing-table-content {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pricing-tables .row .pricing-col:nth-child(2) .pricing-table-head {
  border-top-left-radius: 4px;
}
.pricing-tables .row .pricing-col:nth-child(2) .pricing-table-footer {
  border-bottom-left-radius: 4px;
}
.pricing-tables .row .pricing-col:last-child .pricing-table-head {
  border-top-right-radius: 4px;
}
.pricing-tables .row .pricing-col:last-child .pricing-table-footer {
  border-bottom-right-radius: 4px;
}
@media (min-width: 992px) {
  .pricing-table {
    margin-bottom: 0;
  }
  .pricing-col {
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 991px) {
  .pricing-table-head {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .pricing-table-content span {
    display: block;
  }
  .pricing-table {
    border-right: solid 1px #dddddd;
  }
}
.share-widget-actions {
  padding: 8px 15px;
  background-color: #f5f5f5;
  border: 1px solid #dddddd;
  border-top: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.share-widget-actions:before,
.share-widget-actions:after {
  content: " ";
  display: table;
}
.share-widget-actions:after {
  clear: both;
}
.share-widget-types a {
  display: inline-block;
  width: 2em;
  padding: 5px 0;
  color: #aaaaaa;
  font-size: 17px;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
}
.share-widget-types a:hover {
  text-decoration: none;
  color: #d74b4b;
  background-color: #ffffff;
  border-color: #cccccc;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.share-widget-textarea {
  border-color: #ddd;
  resize: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.feed-item {
  position: relative;
  padding: 0;
  margin: 0;
  padding-top: 22px;
  padding-bottom: 35px;
  padding-left: 40px;
  margin-left: 20px;
  border-left: 2px solid #DDD;
}
.feed-item:last-child {
  padding-bottom: 15px;
}
.feed-subject {
  margin-bottom: 1.5em;
}
.feed-subject a {
  font-weight: 600;
}
.feed-content {
  padding-left: 25px;
}
.feed-content .thumbnail {
  max-width: 100%;
}
.feed-actions {
  padding: 10px 10px;
  margin-top: 2em;
  border-top: 1px dotted #ccc;
}
.feed-actions:before,
.feed-actions:after {
  content: " ";
  display: table;
}
.feed-actions:after {
  clear: both;
}
.feed-actions a {
  color: #999;
  font-size: 13px;
}
.feed-actions a:hover {
  text-decoration: none;
  color: #d74b4b;
}
.feed-actions .pull-left {
  margin: 0 15px 0 0;
}
.feed-actions .pull-right {
  margin: 0 0 0 15px;
}
.feed-icon {
  position: absolute;
  top: 15px;
  left: -23px;
  width: 40px;
  height: 40px;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  line-height: 34px;
  background-color: #999;
  border: 3px solid #FFF;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.35);
}
.progress-stat {
  margin-bottom: 3em;
}
.progress-stat:last-child {
  margin-bottom: 0;
}
.progress-stat-value {
  float: right;
  width: 50%;
  color: #666;
  font-size: 14px;
  font-weight: 600;
  text-align: right;
  text-transform: uppercase;
}
.progress-stat-label {
  float: left;
  width: 50%;
  color: #999;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.progress-stat .progress {
  clear: both;
  position: relative;
  top: .5em;
}
.lt-ie9 .progress-stat-value {
  font-size: 13px;
}
.row-stat {
  padding: 17px 13px 21px;
  margin-bottom: 1.25em;
  border: 1px solid #ddd;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.row-stat-label {
  color: #999;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}
.row-stat-value {
  display: inline-block;
  margin-right: .25em;
  margin-bottom: 0;
}
.row-stat-badge {
  position: relative;
  top: -3px;
  font-size: 85%;
}
.row-stat {
  position: relative;
}
.row-stat:after,
.row-stat:before {
  content: '';
  position: absolute;
  display: block;
  height: 1px;
  bottom: -3px;
  left: 3px;
  right: 3px;
  background-color: #ffffff;
  border-radius: 0 0 3px 3px;
  border: 1px solid #ddd;
  border-top: 0;
}
.row-stat:before {
  bottom: -5px;
  left: 6px;
  right: 6px;
}
.keyvalue-table > tbody > tr > td {
  vertical-align: middle;
  padding-top: 11px;
  padding-bottom: 11px;
  border-top: none;
  border-bottom: 1px solid #ddd;
}
.kv-key {
  color: #555;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.kv-icon {
  position: relative;
  top: 1px;
  width: 30px;
  height: 30px;
  margin-right: .75em;
  color: #444;
  font-size: 15px;
  text-align: center;
  line-height: 30px;
  background-color: #eee;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.kv-icon-default {
  background-color: #fff;
  color: #555;
  border: 1px solid #ccc;
}
.kv-icon-primary {
  background-color: #d74b4b;
  color: #fff;
}
.kv-icon-secondary {
  background-color: #6685a4;
  color: #fff;
}
.kv-icon-tertiary {
  background-color: #bcbcbc;
  color: #fff;
}
.kv-icon-success {
  background-color: #5cb85c;
  color: #fff;
}
.kv-icon-info {
  background-color: #5bc0de;
  color: #fff;
}
.kv-icon-danger {
  background-color: #d9534f;
  color: #fff;
}
.kv-icon-warning {
  background-color: #f0ad4e;
  color: #fff;
}
.kv-value {
  font-size: 18px;
  font-weight: 600;
  text-align: right;
}
.lt-ie9 .kv-value {
  font-size: 15px;
}
.heading-block {
  position: relative;
  margin-bottom: 30px;
  text-align: left;
}
.heading-block h1,
.heading-block h2,
.heading-block h3,
.heading-block h4,
.heading-block h5,
.heading-block h6 {
  margin-bottom: 0;
  color: #354b5e;
}
.heading-block .lead {
  width: 100%;
  margin: 20px auto 0;
  font-size: 19px;
  line-height: 1.5em;
}
.heading-block:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  z-index: 9;
  background: #e6e6e6;
}
.heading-block:after {
  content: "";
  display: block;
  position: relative;
  z-index: 10;
  width: 80px;
  height: 1px;
  margin: 20px 0 0;
  background: #6685a4;
}
.heading-block.heading-minimal {
  margin-bottom: 60px;
}
.heading-block.heading-minimal:before,
.heading-block.heading-minimal:after {
  height: 2px;
}
.heading-block.heading-minimal:before {
  display: none;
}
.heading-block.heading-minimal:after {
  margin-top: 40px;
}
.heading-block.heading-center {
  text-align: center;
}
.heading-block.heading-center:after {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .heading-block .lead {
    width: 70%;
  }
}
.account-bg {
  background-color: #eeeeee;
}
.account-wrapper {
  position: relative;
  width: 90%;
  padding: 0;
  margin: 0 auto;
  text-align: center;
}
.account-wrapper .row > div {
  margin-bottom: 1em;
}
.account-form {
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: left;
}
.account-form .placeholder-hidden {
  display: none;
}
.account-form .form-control {
  height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 1.25em;
}
.account-body {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  color: #444444;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}
.account-footer {
  padding: 0 25px;
  margin-top: 1.5em;
}
.account-or-social {
  display: block;
  margin-top: 1em;
}
@media (min-width: 768px) {
  .account-wrapper {
    width: 450px;
    padding: 0;
  }
  .account-wrapper .row > div {
    margin-bottom: 0;
  }
  .account-body .account-form {
    padding: 0 25px;
    text-align: left;
  }
  .account-or-social {
    margin-top: 2em;
  }
}
.error-container {
  margin-top: 1em;
  text-align: center;
}
.error-code {
  display: inline-block;
  vertical-align: top;
  font-family: 'Oswald', 'Trebuchet MS', 'Open Sans', arial, sans-serif;
  font-size: 100px;
  font-weight: 500;
  line-height: .7em;
}
.error-details {
  display: inline-block;
  vertical-align: top;
  margin-top: 4em;
}
.error-details ul {
  text-align: left;
}
@media (min-width: 768px) {
  .error-container {
    margin-top: 2.5em;
  }
  .error-details {
    width: 250px;
    margin-top: 0;
    margin-left: 25px;
    text-align: left;
  }
}
@media (min-width: 992px) {
  .error-container {
    margin-top: 5em;
  }
  .error-code {
    font-size: 150px;
  }
  .error-details {
    width: 350px;
    margin-left: 50px;
  }
}
.maintenance-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #eee;
}
.maintenance-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  height: 100%;
}
.maintenance {
  position: relative;
  width: auto;
  max-width: 500px;
  padding: 30px 20px;
  margin: 0 auto;
  text-align: center;
}
@media (min-width: 768px) {
  .maintenance {
    height: 440px;
    padding: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
.maintenance hr {
  margin-right: 50px;
  margin-left: 50px;
  border-top-color: #ccc;
  border-bottom: 1px solid #fff;
}
.maintenance-social-icons {
  margin-top: 18px;
}
.maintenance-social-icons .social-icon {
  width: 36px;
  height: 36px;
  color: #666;
  font-size: 20px;
  line-height: 36px;
}
.maintenance-icon {
  position: relative;
  width: 110px;
  height: 105px;
  margin: 0 auto;
}
.maintenance-icon i {
  position: absolute;
}
.maintenance-icon .gear-1 {
  top: 10px;
  left: 0;
  font-size: 72px;
}
.maintenance-icon .gear-2 {
  right: 5px;
  font-size: 36px;
}
.maintenance-icon .gear-3 {
  right: 0;
  bottom: 0;
  font-size: 48px;
}
.maintenance-title {
  margin-top: 25px;
}
.maintenance-description {
  font-size: 16px;
}
.social-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  padding: 0;
  margin: 0 3px;
  color: #2b3d4c;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  border: 1px solid transparent;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.social-icon:hover {
  color: #d74b4b;
  background-color: #fff;
  border-color: #ccc;
}
.demo-btn {
  margin: 0 .5em 1em 0;
}
.demo-element {
  display: inline-block;
  margin: 0 .5em 1em 0;
}
.demo-dropdown-menu {
  display: inline-block;
  position: static;
  top: auto;
  left: auto;
  float: none;
}
.demo-section {
  margin-bottom: 5em;
}
.demo-section:before,
.demo-section:after {
  content: " ";
  display: table;
}
.demo-section:after {
  clear: both;
}
.fontawesome-icon-list {
  margin-top: 3em;
  margin-bottom: 3em;
}
.fontawesome-icon-list > div {
  margin-bottom: 3em;
  text-align: center;
}
.fontawesome-icon-list > div a {
  display: block;
  color: #777;
}
.fontawesome-icon-list > div a:hover {
  color: #d74b4b;
  text-decoration: none;
}
.fontawesome-icon-list > div i {
  display: block;
  margin-bottom: 10px;
  font-size: 22px;
}
.lead {
  color: #888;
}
.img-center {
  margin: 0 auto;
}
.valign-top {
  vertical-align: top !important;
}
.valign-middle {
  vertical-align: middle !important;
}
.valign-bottom {
  vertical-align: bottom !important;
}
.chart-bg {
  padding: 30px 30px 20px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.chart-bg .tickLabel {
  color: #fff;
}
.bg-primary {
  background-color: #d74b4b;
}
.bg-secondary {
  background-color: #6685a4;
}
.bg-tertiary {
  background-color: #bcbcbc;
}
.bg-success {
  background-color: #5cb85c;
}
.bg-danger {
  background-color: #d9534f;
}
.bg-warning {
  background-color: #f0ad4e;
}
.bg-info {
  background-color: #5bc0de;
}
.chart-holder,
.chart-holder-200,
.chart-holder-250,
.chart-holder-300,
.chart-holder-350 {
  width: 98%;
  margin: 0 auto;
}
.chart-holder {
  height: 300px;
}
.chart-holder-100 {
  height: 100px;
}
.chart-holder-150 {
  height: 150px;
}
.chart-holder-200 {
  height: 200px;
}
.chart-holder-250 {
  height: 250px;
}
.chart-holder-300 {
  height: 300px;
}
.chart-holder-350 {
  height: 350px;
}
.clear {
  clear: both;
}
.required {
  color: #d9534f;
}
hr.spacer-xl,
hr.spacer-lg,
hr.spacer-md,
hr.spacer-sm,
hr.spacer-xs {
  margin-top: 0;
  margin-bottom: 0;
  border: none;
}
hr.spacer-xl {
  height: 120px;
}
hr.spacer-lg {
  height: 90px;
}
hr.spacer-md {
  height: 60px;
}
hr.spacer-sm {
  height: 30px;
}
hr.spacer-xs {
  height: 15px;
}
iframe {
  border: none;
}
.semi-bold {
  font-weight: 600;
}
.no-bold {
  font-weight: 300;
}
/*!
 * Gap CSS v0.0.1 (http://gapcss.com)
 * Copyright 2015 Rod Howard
 * Licensed under MIT (https://github.com/rodh/gap/blob/master/LICENSE)
 */
br.xs-0,
br.xs-10,
br.xs-20,
br.xs-30,
br.xs-40,
br.xs-50,
br.xs-60,
br.xs-70,
br.xs-80,
br.xs-90,
br.xs-100,
br.sm-0,
br.sm-10,
br.sm-20,
br.sm-30,
br.sm-40,
br.sm-50,
br.sm-60,
br.sm-70,
br.sm-80,
br.sm-90,
br.sm-100,
br.md-0,
br.md-10,
br.md-20,
br.md-30,
br.md-40,
br.md-50,
br.md-60,
br.md-70,
br.md-80,
br.md-90,
br.md-100,
br.lg-0,
br.lg-10,
br.lg-20,
br.lg-30,
br.lg-40,
br.lg-50,
br.lg-60,
br.lg-70,
br.lg-80,
br.lg-90,
br.lg-100 {
  content: "";
  width: 100%;
  height: 0;
  padding: 0;
  margin: 0;
}
br.xs-0 {
  height: 0px;
  line-height: 0px;
  display: none;
}
br.xs-10 {
  height: 10px;
  line-height: 10px;
  display: inline-block;
}
br.xs-20 {
  height: 20px;
  line-height: 20px;
  display: inline-block;
}
br.xs-30 {
  height: 30px;
  line-height: 30px;
  display: inline-block;
}
br.xs-40 {
  height: 40px;
  line-height: 40px;
  display: inline-block;
}
br.xs-50 {
  height: 50px;
  line-height: 50px;
  display: inline-block;
}
br.xs-60 {
  height: 60px;
  line-height: 60px;
  display: inline-block;
}
br.xs-70 {
  height: 70px;
  line-height: 70px;
  display: inline-block;
}
br.xs-80 {
  height: 80px;
  line-height: 80px;
  display: inline-block;
}
br.xs-90 {
  height: 90px;
  line-height: 90px;
  display: inline-block;
}
br.xs-100 {
  height: 100px;
  line-height: 100px;
  display: inline-block;
}
@media (min-width: 768px) {
  br.sm-0 {
    height: 0px;
    line-height: 0px;
    display: none;
  }
  br.sm-10 {
    height: 10px;
    line-height: 10px;
    display: inline-block;
  }
  br.sm-20 {
    height: 20px;
    line-height: 20px;
    display: inline-block;
  }
  br.sm-30 {
    height: 30px;
    line-height: 30px;
    display: inline-block;
  }
  br.sm-40 {
    height: 40px;
    line-height: 40px;
    display: inline-block;
  }
  br.sm-50 {
    height: 50px;
    line-height: 50px;
    display: inline-block;
  }
  br.sm-60 {
    height: 60px;
    line-height: 60px;
    display: inline-block;
  }
  br.sm-70 {
    height: 70px;
    line-height: 70px;
    display: inline-block;
  }
  br.sm-80 {
    height: 80px;
    line-height: 80px;
    display: inline-block;
  }
  br.sm-90 {
    height: 90px;
    line-height: 90px;
    display: inline-block;
  }
  br.sm-100 {
    height: 100px;
    line-height: 100px;
    display: inline-block;
  }
}
@media (min-width: 992px) {
  br.md-0 {
    height: 0px;
    line-height: 0px;
    display: none;
  }
  br.md-10 {
    height: 10px;
    line-height: 10px;
    display: inline-block;
  }
  br.md-20 {
    height: 20px;
    line-height: 20px;
    display: inline-block;
  }
  br.md-30 {
    height: 30px;
    line-height: 30px;
    display: inline-block;
  }
  br.md-40 {
    height: 40px;
    line-height: 40px;
    display: inline-block;
  }
  br.md-50 {
    height: 50px;
    line-height: 50px;
    display: inline-block;
  }
  br.md-60 {
    height: 60px;
    line-height: 60px;
    display: inline-block;
  }
  br.md-70 {
    height: 70px;
    line-height: 70px;
    display: inline-block;
  }
  br.md-80 {
    height: 80px;
    line-height: 80px;
    display: inline-block;
  }
  br.md-90 {
    height: 90px;
    line-height: 90px;
    display: inline-block;
  }
  br.md-100 {
    height: 100px;
    line-height: 100px;
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  br.lg-0 {
    height: 0px;
    line-height: 0px;
    display: none;
  }
  br.lg-10 {
    height: 10px;
    line-height: 10px;
    display: inline-block;
  }
  br.lg-20 {
    height: 20px;
    line-height: 20px;
    display: inline-block;
  }
  br.lg-30 {
    height: 30px;
    line-height: 30px;
    display: inline-block;
  }
  br.lg-40 {
    height: 40px;
    line-height: 40px;
    display: inline-block;
  }
  br.lg-50 {
    height: 50px;
    line-height: 50px;
    display: inline-block;
  }
  br.lg-60 {
    height: 60px;
    line-height: 60px;
    display: inline-block;
  }
  br.lg-70 {
    height: 70px;
    line-height: 70px;
    display: inline-block;
  }
  br.lg-80 {
    height: 80px;
    line-height: 80px;
    display: inline-block;
  }
  br.lg-90 {
    height: 90px;
    line-height: 90px;
    display: inline-block;
  }
  br.lg-100 {
    height: 100px;
    line-height: 100px;
    display: inline-block;
  }
}
table.dataTable {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
table.table-bordered.dataTable {
  border-collapse: collapse !important;
}
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after {
  color: #d74b4b;
  opacity: 1;
}
#flotTip {
  padding: 3px 6px !important;
  color: #FFF !important;
  font-size: 11px !important;
  text-align: center !important;
  text-decoration: none !important;
  background-color: #222 !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  border: 1px solid #222 !important;
  border-radius: 3px !important;
  /*  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);*/
}
.select2-search {
  padding-top: 0;
}
.ui-icheck {
  display: inline-block;
  position: relative;
  top: -2px;
  margin-right: 6px;
  margin-left: -20px;
}
table.dataTable-helper > thead > tr > th {
  padding-left: 8px;
  padding-right: 8px;
}
table.dataTable-helper > thead > tr > th .form-control {
  display: block !important;
  width: 100% !important;
  font-family: 'Open Sans', 'Trebuchet MS', arial, sans-serif;
  font-size: 13px;
}
table.dataTable-helper th.highlight {
  background-color: #ffd !important;
}
table.dataTable-helper td.highlight {
  background-color: #ffe !important;
}
.navbar {
  margin-bottom: 0;
  border-radius: 0;
}
.navbar .navbar-brand {
  height: 54px;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-profile-avatar {
  position: relative;
  top: -1px;
  width: 30px;
  margin-right: .65em;
  border: 2px solid #87a3bc;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.navbar-profile-label-visible {
  display: inline !important;
}
.navbar-nav > .navbar-profile .dropdown-toggle {
  padding-left: 50px;
}
.navbar-nav > .navbar-profile .dropdown-toggle:hover .navbar-profile-avatar,
.navbar-nav > .navbar-profile.open .navbar-profile-avatar {
  border-color: #ffffff;
}
.navbar-profile-avatar {
  position: absolute;
  top: 50%;
  left: 12px;
  margin-top: -14px;
}
@media (min-width: 768px) {
  .navbar-nav > li > a {
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
.navbar {
  background-color: #2b3d4c;
  border-color: #2b3d4c;
}
.navbar .navbar-brand {
  color: #87a3bc;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: #ffffff;
  background-color: #283846;
}
.navbar .navbar-text {
  color: #ffffff;
}
.navbar .navbar-nav > li > a {
  color: #87a3bc;
  font-weight: 600;
}
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  color: #ffffff;
  background-color: #283846;
}
.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: #253542;
}
.navbar .navbar-nav > .disabled > a,
.navbar .navbar-nav > .disabled > a:hover,
.navbar .navbar-nav > .disabled > a:focus {
  color: #444444;
  background-color: transparent;
}
.navbar .navbar-toggle {
  border-color: transparent;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: #273746;
}
.navbar .navbar-toggle .icon-bar {
  background-color: #ffffff;
}
.navbar .navbar-collapse,
.navbar .navbar-form {
  border-color: #1e2a35;
}
.navbar .navbar-nav > .open > a,
.navbar .navbar-nav > .open > a:hover,
.navbar .navbar-nav > .open > a:focus {
  background-color: #253542;
  color: #ffffff;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #2b3d4c;
  }
  .navbar .navbar-nav .open .dropdown-menu .divider {
    background-color: #2b3d4c;
  }
  .navbar .navbar-nav .open .dropdown-menu > li > a {
    color: #87a3bc;
  }
  .navbar .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #ffffff;
    background-color: #283846;
  }
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #ffffff;
    background-color: #253542;
  }
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444444;
    background-color: transparent;
  }
}
.navbar .navbar-link {
  color: #87a3bc;
}
.navbar .navbar-link:hover {
  color: #ffffff;
}
.navbar .btn-link {
  color: #87a3bc;
}
.navbar .btn-link:hover,
.navbar .btn-link:focus {
  color: #ffffff;
}
.navbar .btn-link[disabled]:hover,
fieldset[disabled] .navbar .btn-link:hover,
.navbar .btn-link[disabled]:focus,
fieldset[disabled] .navbar .btn-link:focus {
  color: #444444;
}
.navbar .navbar-toggle {
  height: 54px;
  padding: 0 15px;
  margin: 0;
  color: #50718d;
  font-size: 18px;
  line-height: 54px;
  border-color: transparent;
}
@media (min-width: 768px) {
  .navbar-collapse .navbar-nav.navbar-left:first-child {
    margin-left: 0;
  }
  .navbar .dropdown-menu:before {
    content: '';
    display: inline-block;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #fff;
    position: absolute;
    top: -7px;
    left: 9px;
  }
  .navbar-right .dropdown-menu:before {
    right: 9px;
    left: auto;
  }
}
.navbar .navbar-nav > .navbar-notification > .dropdown-toggle {
  color: #87a3bc;
  background-color: transparent;
  font-size: 13px;
}
.navbar .navbar-nav > .navbar-notification > .dropdown-toggle .badge {
  padding: 4px 7px;
  font-size: 10px;
  border-top-right-radius: 100px;
  border-top-left-radius: 100px;
  border-bottom-right-radius: 100px;
  border-bottom-left-radius: 100px;
}
.navbar .navbar-nav > .navbar-notification > .dropdown-toggle .navbar-notification-icon {
  color: #50718d;
}
.navbar .navbar-nav > .navbar-notification > .dropdown-toggle:hover,
.navbar .navbar-nav > .navbar-notification > .dropdown-toggle:focus {
  color: #ffffff;
  background-color: #283846;
}
.navbar .navbar-nav > .navbar-notification > .dropdown-toggle:hover .navbar-notification-icon,
.navbar .navbar-nav > .navbar-notification > .dropdown-toggle:focus .navbar-notification-icon {
  color: #ffffff;
}
.navbar .navbar-nav > .navbar-notification.open > .dropdown-toggle,
.navbar .navbar-nav > .navbar-notification.open > .dropdown-toggle:hover,
.navbar .navbar-nav > .navbar-notification.open > .dropdown-toggle:focus {
  color: #ffffff;
  background-color: #253542;
}
.navbar .navbar-nav > .navbar-notification.open > .dropdown-toggle .navbar-notification-icon,
.navbar .navbar-nav > .navbar-notification.open > .dropdown-toggle:hover .navbar-notification-icon,
.navbar .navbar-nav > .navbar-notification.open > .dropdown-toggle:focus .navbar-notification-icon {
  color: #ffffff;
}
.navbar .navbar-nav > .navbar-notification .dropdown-menu {
  min-width: 300px;
}
.navbar .navbar-nav > .navbar-notification .dropdown-menu:before {
  left: 15px;
}
.navbar .navbar-nav > .navbar-notification .notification {
  display: block;
  position: relative;
  padding: 6px 12px 6px 75px;
  border-bottom: 1px solid #ddd;
}
.navbar .navbar-nav > .navbar-notification .notification,
.navbar .navbar-nav > .navbar-notification .notification:hover {
  color: #aaa;
  text-decoration: none;
}
.navbar .navbar-nav > .navbar-notification .notification:hover {
  background-color: #f2f2f2;
}
.navbar .navbar-nav > .navbar-notification .dropdown-header {
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: initial;
  border-bottom: 1px solid #ddd;
  color: #444;
  font-size: 13px;
  font-weight: 600;
}
.navbar .navbar-nav > .navbar-notification .notification-list {
  overflow: hidden;
  position: relative;
}
.navbar .navbar-nav > .navbar-notification.empty .notification-list {
  display: table-cell;
  vertical-align: middle;
  padding: 0 18px;
  text-align: center;
}
.navbar .navbar-nav > .navbar-notification.empty .notification-link {
  display: none;
}
.navbar .navbar-nav > .navbar-notification.empty .slimScrollBar {
  display: none !important;
}
.navbar .navbar-nav > .navbar-notification .notification-title,
.navbar .navbar-nav > .navbar-notification .notification-description,
.navbar .navbar-nav > .navbar-notification .notification-time {
  display: block;
  font-size: 12px;
}
.navbar .navbar-nav > .navbar-notification .notification-title {
  padding-bottom: 1px;
  color: #222;
  font-weight: 600;
}
.navbar .navbar-nav > .navbar-notification .notification-description {
  padding-bottom: 3px;
  color: #666;
  font-size: 12px;
}
.navbar .navbar-nav > .navbar-notification .notification-time {
  font-size: 11px;
  font-style: italic;
  color: #bbb;
}
.navbar .navbar-nav > .navbar-notification .notification-icon {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 50px;
  height: 50px;
  margin: -25px 12px 0 0;
  font-size: 14px;
  line-height: 50px;
  text-align: center;
}
.navbar .navbar-nav > .navbar-notification .notification-icon img {
  width: 50px;
  height: 50px;
}
.navbar .navbar-nav > .navbar-notification .notification-icon i {
  width: 50px;
  height: 50px;
  text-align: center;
  font-size: 28px;
  display: block;
  line-height: 40px;
}
.navbar .navbar-nav > .navbar-notification .notification-link {
  text-align: center;
  padding: 8px 0 !important;
  border-top: 1px solid #ddd;
  display: block;
  text-decoration: none !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px !important;
  height: auto !important;
}
.navbar .navbar-nav > .navbar-notification .notification-link:hover {
  background: #eee;
}
.navbar .navbar-nav > .navbar-notification .notification-empty-title {
  color: #d74b4b;
}
.navbar .navbar-nav > .navbar-notification .notification-empty-text {
  color: #777;
}
@media (min-width: 768px) {
  .navbar .navbar-nav > .navbar-notification > .dropdown-toggle {
    font-size: 18px;
  }
  .navbar .navbar-nav > .navbar-notification > .dropdown-toggle .badge {
    position: absolute;
    top: 6px;
    right: 3px;
  }
}
@media (max-width: 767px) {
  .navbar-notification .dropdown-menu {
    display: none !important;
  }
}
.mainnav {
  position: relative;
  margin-bottom: 40px;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
}
.mainnav:after {
  content: '';
  position: absolute;
  top: 100%;
  z-index: -1;
  width: 100%;
  height: 50px;
  margin-top: 2px;
  background-image: linear-gradient(to bottom, #f2f2f2 0%, #ffffff 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2f2f2', endColorstr='#ffffffff', GradientType=0);
}
.mainnav .dropdown-menu {
  min-width: 200px;
}
.mainnav-toggle {
  display: block;
  padding: 12px 0;
  margin: 0 -15px;
  color: #d74b4b;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  background: transparent;
}
.mainnav-toggle:hover {
  color: #d13232;
  background: transparent;
}
.mainnav-form {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.mainnav-form-btn {
  position: absolute;
  top: 17px;
  right: 2px;
  color: #d74b4b;
  background-color: transparent;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.mainnav-form-btn:hover {
  color: #9a2222;
}
.mainnav-menu {
  padding: 0;
  margin: 0 -15px;
  list-style-type: none;
}
.mainnav-menu > li {
  position: relative;
}
.mainnav-menu > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #999999;
  font-weight: 600;
  background: #ffffff;
}
.mainnav-menu > li > a:hover {
  text-decoration: none;
  background-color: #f6f6f6;
}
.mainnav-menu > li > a,
.mainnav-menu > li > a:hover,
.mainnav-menu > li > a:focus {
  text-decoration: none;
}
.mainnav-menu > li.open > a,
.mainnav-menu > li.open > a:hover,
.mainnav-menu > li.open > a:focus {
  color: #555555;
  background-color: #f4f4f4;
}
.mainnav-menu > li.active > a,
.mainnav-menu > li.active > a:hover,
.mainnav-menu > li.active > a:focus {
  color: #d74b4b;
}
.mainnav-menu .mainnav-caret:before {
  display: inline-block;
  position: relative;
  top: -1px;
  margin-left: .25em;
  font-family: 'FontAwesome';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  content: "\f0d7";
  -webkit-font-smoothing: antialiased;
}
@media (min-width: 768px) {
  .mainnav-toggle {
    display: none;
  }
  .mainnav-collapse {
    width: auto;
    padding-right: 0;
    padding-left: 0;
    border-top: 0;
    box-shadow: none;
  }
  .mainnav-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
    visibility: visible !important;
  }
  .mainnav-form {
    position: absolute;
    top: 50%;
    right: 15px;
    height: 34px;
    margin-top: -17px;
    padding-top: 0;
    padding-bottom: 0;
    border-top: none;
    border-bottom: none;
  }
  .mainnav-search-query {
    position: relative;
    width: 120px;
    padding-left: 30px;
    font-size: 13px;
    border: none;
    border-bottom: 1px solid #ddd;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .mainnav-search-query:focus {
    top: 0;
    border: none;
    border-bottom: 2px solid #d74b4b;
    box-shadow: none;
  }
  .mainnav-form-btn {
    top: 2px;
    right: auto;
    left: 0;
    padding-right: 5px;
    padding-left: 5px;
  }
  .mainnav-menu {
    margin-left: -15px;
  }
  .mainnav-menu > li {
    float: left;
    margin-right: 0;
  }
  .mainnav-menu > li > a {
    padding: 21px 15px;
    font-size: 16px;
    letter-spacing: -1px;
    border-bottom: 1px solid transparent;
    border-bottom-color: transparent;
  }
  .mainnav-menu > li > a:hover {
    background-color: #f4f4f4;
    border-bottom-color: transparent;
  }
  .mainnav-menu > li.active > a {
    border-bottom-color: #d74b4b;
  }
  .mainnav-menu .mainnav-caret {
    font-size: 13px;
  }
  .mainnav-menu > li > .dropdown-menu {
    top: 97%;
    border-color: #ccc;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .mainnav-menu > li > .dropdown-menu:before,
  .mainnav-menu > li > .dropdown-menu:after {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #CCC;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -7px;
    left: 9px;
  }
  .mainnav-menu > li > .dropdown-menu:after {
    border-left-width: 6px;
    border-right-width: 6px;
    border-bottom-width: 6px;
    border-bottom-color: #fff;
    top: -6px;
    left: 10px;
  }
}
@media (min-width: 992px) {
  .mainnav-search-query {
    width: 225px;
  }
  .mainnav-menu > li {
    margin-right: 10px;
  }
  .mainnav-menu > li > a {
    font-size: 18px;
  }
  .mainnav-menu .mainnav-caret {
    top: -2px;
    font-size: 18px;
  }
  .lt-ie9 .mainnav-menu > li > a {
    font-size: 16px;
  }
  .lt-ie9 .mainnav-menu .mainnav-caret {
    font-size: 14px;
  }
}
@media (min-width: 1200px) {
  .mainnav-menu > li > a {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .mainnav-menu .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }
  .mainnav-menu .dropdown-menu > li > a {
    padding-left: 25px;
  }
  .mainnav-menu > li.is-open .dropdown-menu {
    display: block !important;
  }
}
.footer {
  padding: 20px 0;
  color: #ffffff;
  background-color: #2f4354;
}
.footer:before,
.footer:after {
  content: " ";
  display: table;
}
.footer:after {
  clear: both;
}
.footer p {
  margin-bottom: 0;
}
.footer a {
  color: #98b0c5;
}
.footer a:hover {
  color: #ffffff;
}
html,
body {
  height: 100%;
}
#wrapper {
  min-height: 100%;
  height: auto;
  padding: 0 0 60px;
  margin: 0 auto -60px;
}
.footer {
  height: 60px;
}
